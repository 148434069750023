<template>
  <v-container fluid v-if="view.public && !round.published">
    <h2 class="ma-12 text-center">
      <v-avatar color="red">
        <v-icon color="white">fas fa-eye-slash</v-icon>
      </v-avatar>
      <br class="hidden-md-and-up">
      The pools have not been published yet.
    </h2>
  </v-container>
  <v-container fluid class="pa-0" v-else>
    <v-alert
      :value="view.admin && !round.published && round.flights.length > 0"
      transition="scale-transition"
      type="warning"
      prominent tile
      class="mb-0"
    >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{division.name}} {{round.name}} are not published. </strong>
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click.stop="publish"
              :loading="loading"
            >publish</v-btn>
          </v-col>
        </v-row>
    </v-alert>
    <v-alert
      :value="view.admin && !!round.complete && !round.locked"
      transition="scale-transition"
      type="warning"
      prominent tile
      class="mb-0"
    >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{division.name}} {{round.name}} appears to be done. </strong>
          </v-col>
          <v-col class="shrink">
            <pool-finish-dialog></pool-finish-dialog>
          </v-col>
        </v-row>
    </v-alert>
    <template v-if="division.hydrated && !hideTabs">
      <v-tabs
        v-if="division.hydrated && !hideTabs"
        background-color="color3"
        slider-color="color3Text"
        active-class="color3Text color3--text"
        class="color3 color3Text--text"
        show-arrows
        center-active
      >
        <v-tab
          v-for="(tab, i) in tabs"
          :key="i"
          exact
          :to="tab.to"
          :class="tab.active ? 'color3--text' : 'color3Text--text'"
        >
          <v-icon v-if="tab.icon" :color="y=tab.active ? 'color3' : 'color3Text'" class="pr-1" small>{{tab.icon}}</v-icon>
          {{tab.text}}
        </v-tab>
      </v-tabs>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-btn
          color="color3"
          text
          class="xsmall my-0 px-1"
          :class="{'mx-0': xs}"
          @click.stop="courts = !courts"
        >toggle {{courts ? 'names' : 'courts'}}</v-btn>
        <v-btn
          color="color3"
          text
          class="xsmall my-0 pl-1"
          v-if="view.admin"
          :class="{'mx-0': xs}"
          @click.stop="settings = !settings"
        >
          <v-icon>fas fa-cog</v-icon>
        </v-btn>
      </v-row>
      <v-expand-transition>
        <v-row v-if="settings" class="px-3">
          <v-col cols="12">
            <v-card flat>
              <round-settings
                :round="round"
                :fromSelect="fromSelections"
                :divisionId="division.id"
                :locked="tournament.locked || division.complete"
              ></round-settings>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
    </template>
    <router-view></router-view>

  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import { formatArray } from '@/Filters'
const PoolFinishDialog = () => import('@/components/Tournament/Pools/PoolFinishDialog')
const RoundSettings = () => import('@/components/Tournament/RoundSettings/RoundSettings.vue')

export default {
  mixins: [RouteMixin],
  data () {
    return {
      loading: false,
      courts: false,
      settings: false
    }
  },
  computed: {
    ...mapGetters(['getDivision', 'admin', 'view', 'tournament']),
    hideTabs () {
      return this.pools.length === 1 && (['Matches', 'Friday', 'Saturday', 'Sunday'].includes(this.round.name) || this.round.freePlay) && !this.view.admin
    },
    finishQ () {
      return (this.view.admin && !!this.round.complete && !this.round.locked)
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    pools () {
      const a = 0
      const b = 999
      return this.round && this.round.flight ? this.round.flight.pools.filter(f => f.number > a && f.number < b) : []
    },
    tabs () {
      const tabs = []
      if (!this.division.hydrated) return tabs
      if (this.division.poolCount > 0) {
        tabs.push({
          i: 0,
          text: 'Home',
          to: { name: 'pools-home', params: { tournamentId: this.tournamentId, divisionId: this.divisionId, dayId: this.dayId } },
          active: this.$route.name === 'pools-home'
        })
        this.pools.forEach(pool => {
          tabs.push({
            text: this.courts ? `Ct ${pool.courts ? formatArray(pool.courts) : '?'}` : this.round.freePlay ? 'Matches' : `Pool ${pool.name}`,
            to: { name: 'pool-sheet', params: { poolId: pool.id } },
            icon: pool.locked ? 'fas fa-lock' : null,
            i: pool.number,
            active: this.$route.name === 'pool-sheet' && this.poolId === pool.id
          })
        })
      }
      return tabs.sort(firstBy('i'))
    },
    fromSelections () {
      return this.division.days.map(d => {
        return {
          id: d.id,
          number: d.number,
          poolPlay: d.poolPlay,
          bracketPlay: d.bracketPlay,
          text: `${d.name} Results`,
          i: null
        }
      })
    }
  },
  methods: {
    publish () {
      this.loading = true
      this.round.patch({
        published: true
      })
        .then(() => { this.round.published = true })
        .catch(err => { console.log(err.response) })
        .finally(() => { this.loading = false })
    },
    checkRedirect () {
      if (this.view.admin && (!(this.round && this.round.pools && this.round.pools.length) && !this.round.flightsCreated) && this.division.hydrated) {
        this.$router.push({ name: 'division-rounds', params: { replacement: true } })
      }
    }
  },
  watch: {
    'view.admin' () {
      this.checkRedirect()
    },
    'division.hydrated' (val) {
      if (val) {
        this.checkRedirect()
      }
    }
  },
  mounted () {
    if (this.division.hydrated) {
      this.checkRedirect()
    }
  },
  components: {
    PoolFinishDialog,
    RoundSettings
  }
}
</script>

<style scoped>
</style>
